@let breakpoints = breakpoints$ | async;
@let title = headerStateService.title();

@if (!breakpoints.isDesktop) {
  <header rpHeader class="shell__header" [headerTitle]="title" [(isSidebarCollapsed)]="isSidebarCollapsed" isDesktop="false"></header>
}

<aside class="shell__sidebar" rpSidebar [isDesktop]="breakpoints.isDesktop" [(isSidebarCollapsed)]="isSidebarCollapsed">
  <main class="shell__main">
    @if (breakpoints.isDesktop) {
      <header rpHeader class="shell__header" [headerTitle]="title" isDesktop></header>
    }

    <div class="shell__content">
      @if (!breakpoints.isDesktop) {
        <rp-mobile-sub-header />
      }

      <router-outlet />
    </div>
  </main>
</aside>
